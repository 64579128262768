import { setFinanceCourseOptions } from './finance/common';
import { pcaKey, postCodeSeparator, updateFinanceOptions } from './variables';
$( document ).ready(function() {

    if (jQuery("#countdown").length > 0) {
        jQuery("#countdown").countdown(jQuery('#discount-end-date').val()).on('update.countdown', function (event) {

            if (jQuery(window).width() < 640) {
                if (event.strftime('%D') < 1) {
                    jQuery('#countdown-days').parent('li').remove();
                } else {
                    //jQuery('#countdown-seconds').parent('li').remove();
                }
            }

            jQuery('#countdown-days').text(
                event.strftime('%D')
            );

            jQuery('#countdown-hours').text(
                event.strftime('%H')
            );

            jQuery('#countdown-minutes').text(
                event.strftime('%M')
            );

            jQuery('#countdown-seconds').text(
                event.strftime('%S')
            );
        });
    }

    jQuery('.field-full-payment').click(function(event) {
        jQuery('input[name="payplan"]').val('pay_now');
        updatePricing();
    });

    jQuery('.field-finance').click(function(event) {
        jQuery('input[name="payplan"]').val('finance');
        updatePricing();
    });

    jQuery('.field-online').click(function(event) {
        jQuery('input[name="studymethod"]').val('online');
        updatePricing();
    });

    jQuery('.field-course-pack').click(function(event) {
        jQuery('input[name="studymethod"]').val('course-pack');
        updatePricing();
    });

    function updatePricing() {
        jQuery('.payment-option').hide();
        //console.log($('input[name="payplan"]').val());
        //console.log($('input[name="studymethod"]').val());
        if (jQuery('input[name="payplan"]').val() === 'pay_now') {
            if (jQuery('input[name="studymethod"]').val() === 'online') {
                jQuery('#full-payment-online').show();
            } else {
                jQuery('#full-payment-pack').show();
            }
        } else {
            if (jQuery('input[name="studymethod"]').val() === 'online') {
                jQuery('#finance-online').show();
                var onlineTotal = jQuery('#instalmentprice_online').text();
                setFinanceCourseOptions(onlineTotal, updateFinanceOptions);
            } else {
                jQuery('#finance-pack').show();
                var packTotal = jQuery('#instalmentprice_coursepack').text();
                setFinanceCourseOptions(packTotal, updateFinanceOptions);
            }
        }
    }
});
