require('./bootstrap');
require('./partials/steps/script');
require('./partials/add-to-basket');
require('./partials/finance');
require('./partials/checkout');
require('./partials/ajax-forms');
require('./partials/complete');


// require('./partials/steps/jquery.validate');
// require('./partials/jquery.steps.min');
// require('./partials/jquery.validate.1.19.1.min');
// require('./partials/jquery.validate.1.19.1.additional-methods.min');
