import { finance } from './finance-resolver';

export function updateFinanceDetails(data, options = {}) {

    var prefix = '';

    if (options.hasOwnProperty('prefix')) {
        var prefix = options.prefix;
    }
    var financeProduct = data.financeProduct;
    if(financeProduct.length <= 0 ) financeProduct =data.financeProduct.finance ;
    jQuery('input[name="finance-product"]').val(financeProduct);
    jQuery('input[name="finance-deposit"]').val(data.depositAmount);
    jQuery('.finance-monthly').text(prefix+data.regularPayment.toFixed(2)).fadeIn();
    jQuery('.finance-per-month-course').text(prefix+data.regularPayment.toFixed(2)).fadeIn();
    jQuery('.finance-per-month-stick').text('From: £'+data.regularPayment.toFixed(2)).fadeIn();
    jQuery('.finance-deposit').text(prefix+parseFloat(data.depositAmount).toFixed(2));
    jQuery('.finance-cashprice').text(prefix+parseFloat(data.cashPrice).toFixed(2)).fadeIn();
    jQuery('.finance-loanamount').text(prefix+parseFloat(data.loanAmount).toFixed(2)).fadeIn();
    jQuery('.finance-loanrepayment').text(prefix+parseFloat(data.loanRepayment).toFixed(2)).fadeIn();
    jQuery('.finance-costofloan').text(prefix+parseFloat(data.costOfLoan).toFixed(2)).fadeIn();
    jQuery('.finance-amountpayable').text(prefix+parseFloat(data.totalRepayable).toFixed(2)).fadeIn();
    jQuery('.finance-numberofpayments').text(data.duration).fadeIn();

    if (jQuery('#financeoptions').length > 0) {
        jQuery('#financeoptions').removeClass('is-hidden');
        jQuery('.basket--summary').removeClass('is-hidden');
    }
}

export function setFinanceOptions(options) {
    //set default value to product
    var product = (jQuery('#finance-term-select').val() == null?jQuery('#finance-term-select > option')[1].value:jQuery('#finance-term-select').val());

    if (jQuery(".current_total").length > 0) {
        var total = jQuery(".current_total").html().replace('£', '');

        if (jQuery('input[name="finance-term"]').length > 0) {
            jQuery('input[name="finance-term"]').val(product);
        }
    } else {
        var studyMethod = jQuery("input[name=studymethod]").val();
        // var total = "online" === studyMethod ? jQuery("#instalmentprice_online").text().replace(",", "") : jQuery("#instalmentprice_coursepack").text().replace(",", "");
        var total = jQuery("#instalmentprice").text().replace(",", "");
    }

    var depositPercentage = jQuery('#finance-deposit-select').val();
    finance.getFinanceDetails(product, total, depositPercentage, updateFinanceDetails, options);
}

export function setFinanceCourseOptions(total, options) {
    var product = defaultFinanceProduct;
    var total = total;
    var depositPercentage = defaultFinanceDeposit;

    finance.getFinanceDetails(product, total, depositPercentage, updateFinanceDetails, options);
}
