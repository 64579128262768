import { setFinanceOptions } from './finance/common';
import { finance } from './finance/finance-resolver';
import { updateFinanceOptions } from "./variables";


jQuery(document).ready(function() {

    if (jQuery('#finance-term-select').length > 0) {
        setFinanceOptions(updateFinanceOptions);
    };

    // jQuery(document).on('click', '#accept-finance-options', function(event) {
    //     if (jQuery('#add-to-basket-form').length > 0) {
    //         jQuery('#add-to-basket-form').submit();
    //     } else {
    //         jQuery.magnificPopup.close();
    //     }
    // });

    jQuery('#add-to-basket-form').submit( function(event) {
        event.preventDefault();
        window.dataLayer.push({
            'event': 'finance_select_options'
        });
        event.currentTarget.submit();
    });
    jQuery('#enrol_now').click( function(event) {
        event.preventDefault();
        if (jQuery('.field-finance').prop("checked"))
        {


            jQuery('.js-popup-inline').magnificPopup({
                type: 'inline',
                removalDelay: 300,
                mainClass: 'mfp-fade',
                callbacks: {
                    beforeOpen: function() {
                        var $triggerEl = jQuery(this.st.el),
                            newClass = $triggerEl.data("modal-class");
                        if (newClass) {
                            this.st.mainClass = this.st.mainClass + ' ' + newClass;
                        }
                        setFinanceOptions(updateFinanceOptions);
                        // finance.refreshFinancePricing(currentSite);
                    },
                    open: function(){
                        jQuery('#finance-term-select').val(jQuery('#finance-term-select > option')[1].value);
                        //alert($('#finance-term-select').find(":selected").val());
                        // setFinanceOptions(updateFinanceOptions);
                        // finance.refreshFinancePricing(currentSite);
                    }
                }
            }).magnificPopup('open');

        }
        else{
            jQuery.magnificPopup.close();
            jQuery( "#add-to-basket-form" ).submit();
        }
    });

    jQuery('#edit-finance').click( function(event) {
        $('.js-popup-inline').magnificPopup({
            type: 'inline',
            removalDelay: 300,
            mainClass: 'mfp-fade',
            callbacks: {
                beforeOpen: function () {
                    var jQuerytriggerEl = $(this.st.el),
                        newClass = $triggerEl.data("modal-class");
                    if (newClass) {
                        this.st.mainClass = this.st.mainClass + ' ' + newClass;
                    }
                    setFinanceOptions(updateFinanceOptions);
                }
            }
        }).magnificPopup('open');
    });

    jQuery( "input[name='radio1']" ).change(function() {
       if(jQuery(this).attr('class') == 'form__field field-full-payment'){
           jQuery('#enrol_now').removeAttr('href');
       }
       else{
           var attr = jQuery('#enrol_now').attr('href');
           if (!jQuery('#enrol_now').attr('href')) {
               jQuery('#enrol_now').attr('href', '#finance-popup');
           }
       }
    });

    if (jQuery('#checkout-finance-details').length > 0) {
        setFinanceOptions(updateFinanceOptions);
    }

    jQuery('#finance-term-select,#finance-deposit-select').change(function (event) {
        setFinanceOptions(updateFinanceOptions);
    });

    if (jQuery('#checkout-finance-details').length > 0) {
        finance.refreshFinancePricing();
    }


    document.addEventListener('ajax-page-load', function(e) {console.log('here');
        finance.refreshFinancePricing();
        jQuery('html').scrollTop(0);
    });

    if(jQuery(".field-finance").prop("checked")){
        jQuery('input[name="payplan"]').val('finance');
        jQuery('.payment-option').hide();

        if (jQuery('input[name="studymethod"]').val() === 'online') {
            jQuery('#finance-online').show();
        } else {
            jQuery('#finance-pack').show();
        }
    }
});

jQuery(document)
    .ajaxStart(function () {
        jQuery('.loading').each(function (){
            jQuery(this).parent().find('.ajax-field').hide();
            jQuery(this).removeClass('stopspinner');
        });
    })
    .ajaxStop(function () {
        jQuery('.loading').each(function (){
            jQuery(this).addClass('stopspinner');
            jQuery(this).parent().find('.ajax-field').show();
        });
    });
