import { creditCardType } from "./checkout/cards";
import { setFinanceCourseOptions, updateFinanceDetails } from './finance/common';
import { finance } from './finance/finance-resolver';
import { clearFinanceApplicantDetails, getRowHeight } from './steps/script';
import { updateFinanceOptions } from './variables';


//import { updateFinanceDetails } from '../../../js/lib/finance/common';
//import { finance } from '../../../js/lib/finance/finance-resolver';
$.noConflict();
jQuery( document ).ready(function() {

    if (jQuery('.current_total').length > 0) {
        var subTotal = jQuery(".current_total").html().replace(",","").split(getCookie('symbol'))[1];
    }

    pca.on("load", function(type, id, control) {
        control.listen("populate", function(address) {
            if(id == 'BX31-MA84-JW58-AU83'){
                jQuery('.address_billing_block_hidden').css('display', 'block');
            }else{
                jQuery('.address_block_hidden').css('display', 'block');
                jQuery('#addressfinder_student').val(address.Label.replace(/\n/g, ", "));
                if(address.ProvinceName == 'Las Palmas' || address.ProvinceName == "Santa Cruz de Tenerife"){
                    jQuery('#student_country').val("ESC").change();
                
                // If postcode starts with BT - Northern Ireland 
                } else if (address.PostalCode.search('BT') == 0){
                    jQuery('#student_country').val("GB4").change();
                } else {
                    var CountryIso2 = 'GB1';
                    if(address.CountryIso2 == 'GB'){
                        CountryIso2 = 'GB1'
                    }else{
                        CountryIso2 = address.CountryIso2;
                    }
                        jQuery('#student_country').val(CountryIso2).change();
                }
            }

            getRowHeight();
        });
    });
    // jQuery('.addressfinder__input').keyup(function(event) {
    //     var type = jQuery(this).attr('id').split('_')[1];
    //     getRowHeight();
    //     jQuery('#find-button_'+type).parent('div').show();
    //
    //     if (jQuery(this).val().length > 1) {
    //         capturePostcode({ Key: pcaKey, Text: jQuery(this).val() }, listAddressResults(type));
    //     } else {
    //         jQuery('#addressresults_'+type).parent('div').hide();
    //     }
    //
    // });

    // jQuery('.find-button').click(function(event) {
    //     event.preventDefault();
    //     var type = jQuery(this).attr('id').split('_')[1];
    //     jQuery(this).parent('div').hide();
    //     jQuery('#addressfinder_'+type).val('');
    //     jQuery('#addressresults_'+type).parent('div').hide();
    //     getRowHeight();
    // });

    jQuery('#find-button_student').click(function(){
        jQuery('.addressfinder__input').hide();
        jQuery('.addressfinder__input').removeAttr('required');;
        jQuery('.address_block_hidden').css('display', 'block');
        getRowHeight();
    });
    jQuery('#find-button_billing').click(function(){
        jQuery('.addressfinder_billing__input').hide();
        //jQuery('.addressfinder__input').removeAttr('required');;
        jQuery('.address_billing_block_hidden').css('display', 'block');
        getRowHeight();
    });
    jQuery('#student_dob').keyup(function(event) {
        if (jQuery('#student_dob').val().length == 10) {
            var age = getAge(jQuery('#student_dob').val());
            console.log(parseInt(age));
            if(parseInt(age) < 18){
                if(jQuery('#student-billing').is(":checked")){
                    jQuery('#billing').trigger('click');
                }
            }else if(parseInt(age) >= 18){
                if(jQuery('#billing').is(":checked")){
                    jQuery('#student-billing').trigger('click');

                }
            }

         }
    });
    jQuery('#additional_requirement_switch-input').click(function(){
        jQuery('.additional_requirements_area').toggle();
        //jQuery('.addressfinder__input').removeAttr('required');
        getRowHeight();
    });
    jQuery(document).on('click', '#student-billing, #billing', function(event) {
        var name = jQuery(this).attr('id');

        if(name == "billing"){
            jQuery(".billing").parent().addClass( "is-active" );
            jQuery(".student-billing").parent().removeClass( "is-active" );

            if(jQuery('#student-billing').is(":checked")) {
                jQuery('#student-billing').prop('checked', false);
                jQuery('#billing').prop('checked', true);
                jQuery("#"+name+"_details_container").toggle("slow", function() {getRowHeight();});
            }
            if(!jQuery('#billing').is(":checked")) {
                jQuery('#billing').prop('checked', true);
                if(jQuery('#student-billing').is(":checked")) {
                    jQuery('#student-billing').prop('checked', false);
                    jQuery("#"+name+"_details_container").toggle("slow", function() {getRowHeight();});
                }


            }
        }else if(name == "student-billing"){
            jQuery(".student-billing").parent().addClass( "is-active" );
            jQuery(".billing").parent().removeClass( "is-active" );

            if(jQuery('#billing').is(":checked")) {
                jQuery('#student-billing').prop('checked', true);
                jQuery('#billing').prop('checked', false);
                jQuery("#billing_details_container").toggle("slow", function() {getRowHeight();});
            }
            if(!jQuery('#student-billing').is(":checked")) {
                jQuery('#student-billing').prop('checked', true);
                if(jQuery('#billing').is(":checked")) {
                    jQuery('#billing').prop('checked', false);
                    jQuery("#billing_details_container").toggle("slow", function() {getRowHeight();});
                }
            }
        }
        //jQuery('#student-billing').is(":checked")

        //jQuery("#"+name+"_details_container").toggle("slow", function() {getRowHeight();});

    });
    //
    // jQuery(document).on('click', '.address_result', function(event) {
    //     var addressType = jQuery(this).attr('id').split(postCodeSeparator)[1];
    //     var type = jQuery(this).parent('div').attr('id').split('_')[1];
    //     if (addressType === 'Address') {
    //         retrievePostcode({ Key: pcaKey, Id: jQuery(this).attr('id').split(postCodeSeparator)[2] }, selectAddressResult(type));
    //     } else {
    //         capturePostcode({ Key: pcaKey, Text: jQuery(this).attr('id').split(postCodeSeparator)[3], Container: jQuery(this).attr('id').split(postCodeSeparator)[2] }, listAddressResults(type));
    //     }
    //     jQuery('.address_block_hidden').css('display', 'block');
    //     resetRowHeight();
    // });

    jQuery(document).on('click', '#applicant-finance', function(event) {
        var name = jQuery(this).attr('id');
        jQuery("#finance_details_container").show("slow", function() {
            clearFinanceApplicantDetails();
            getRowHeight();
        });

    });

    jQuery(document).on('click', '#discount-code-btn', function(event) {
        jQuery('.discount-code').css('display', 'block');
        jQuery('.discount-code__note-text').css('display', 'none');
        jQuery(this).css('display', 'none');
    });

    jQuery(document).on('click', '#add-discount-code', function(event) {
        var original_total = subTotal;
        var new_total = original_total ;
        jQuery.ajax({
            type: "GET",
            url: oscdomain + "/apply-coupon-code",
            data: {code: jQuery('#discount-code').val()},
            success: function (result) {
                if(result[0] != 'error') {
                    new_total = original_total - result;
                    subTotal = new_total.toString();
                    // jQuery('.chargetotal').val(new_total);
                    jQuery('#input-discount-code').val(jQuery('#discount-code').val());
                    jQuery('.sub-total').removeClass('sub-total').wrap('<s class="offer-strick" id="originalBasketVal"></s>');
                    jQuery('#originalBasketVal').after('<strong class="sub-total">'+getCookie('symbol')+parseFloat(new_total).toFixed(2)+'</strong>');
                    jQuery('.discount-code__form').css('display', 'none');
                    jQuery('.discount-code-applied').css('display', 'block');
                    jQuery('body').addClass('discount-active');
                    recalculateTotal();
                }
                else{
                    jQuery('#add-discount-code').parent().find(".discount-code__error").remove();
                    jQuery('#add-discount-code').after('<p class="discount-code__error">'+result[1]+'</p>')
                }
            },
            error: function(event){
                console.log('complete - '+event);
            }
        });
    });

    jQuery(document).on('click', '#applicant-student', function(event) {
        var name = jQuery(this).attr('id');
        jQuery("#finance_details_container").hide("slow", function() {
            setFinanceApplicantDetails();
            getRowHeight();
        });

    });

    jQuery('.addonrow').click(function (){
        var currentTotal =  parseFloat(jQuery('input[name="chargetotal"]').val());
        if(isNaN(currentTotal)){
            var total_srt = jQuery("strong.current_total").first().html();
            currentTotal = parseFloat(total_srt.slice(1));
        }

        if(this.checked){

            jQuery(this)
                .parent()
                .addClass("is-selected");

            if(!jQuery('.addon'+this.value).length) {
                jQuery(".cart-addons").before('' +
                    '<div class="cart-detail cart-studymethod cart-delivery row addon' + this.value + '">' +
                    '<div class="col-6">' + capitalizeFirstLetter(this.getAttribute('addon-text')) + '</div>' +
                    '<div class="col-6 u-right-align"><span><strong>' + getCookie('symbol') + this.getAttribute('cost') + '</strong></span></div>' +
                    '</div>'
                );
                // currentTotal = (currentTotal + parseFloat(this.getAttribute('cost'))).toFixed(2);
                // jQuery('input[name="item_price"]').val(currentTotal);
                // var symbol = jQuery('.current_total').html()[0];
                // jQuery('.current_total').html(symbol+currentTotal);
                // jQuery('.chargetotal').val(currentTotal);

                var current_addons = JSON.parse(jQuery("#addons").val());
                if(jQuery.inArray(this.value, current_addons) == -1) current_addons.push(this.value);
                jQuery('#addons').val(JSON.stringify(current_addons));
                //jQuery('#addons').val('['+JSON.stringify(currentaddons.push(this.value))+']');

            }
        }
        else {

            jQuery(this)
                .parent()
                .removeClass("is-selected");

            if(jQuery('.addon'+this.value).length){
                // currentTotal = (currentTotal - parseFloat(this.getAttribute('cost'))).toFixed(2);
                // jQuery('input[name="item_price"]').val(currentTotal);
                // var symbol = jQuery('.current_total').html()[0];
                // jQuery('.current_total').html(symbol+currentTotal);
                // jQuery('.chargetotal').val(currentTotal);
                jQuery('.addon'+this.value).remove();
            }

            var current_addons = JSON.parse(jQuery("#addons").val());
            current_addons.splice( jQuery.inArray(this.value, current_addons), 1 );
            jQuery('#addons').val(JSON.stringify(current_addons));
        }
        recalculateTotal();
    });

    jQuery(document).on('click', '#delivery, #billing', function(event) {
        //var name = jQuery(this).attr('id');
        //jQuery("#"+name+"_details_container").toggle("slow", function() {getRowHeight();});

    });

    jQuery('select[name="student_country"]').change(function(e) {

        var text = jQuery('select[name="student_country"] option:selected').text().split(getCookie('symbol'))[1];

        if (parseFloat(text) > 0) {
            // var total = getCookie('symbol') + parseFloat(parseFloat(text) + parseFloat(subTotal.replace(",",""))).toFixed(2);
            var deliveryPrice = getCookie('symbol')+(parseFloat(text)).toFixed(2);
        } else {
            // var total = getCookie('symbol')+parseFloat(subTotal.replace(",","")).toFixed(2);
            //var total = getCookie('symbol')+subTotal;
            var deliveryPrice = 'FREE';
        }

        jQuery('#delivery_price').text(deliveryPrice);
        // jQuery('.current_total').text(total);
        // jQuery('.chargetotal').val(total.replace(getCookie('symbol'), ""));
        recalculateTotal();
        /*
        Unnecessary?
        if (jQuery('input[name="finance-term"]').length > 0) {

            var product = jQuery('#finance-term-select').val();
            var total = jQuery(".current_total").html();

            jQuery('input[name="finance-term"]').val(product);
            var depositPercentage = jQuery('#finance-deposit-select').val();

            finance.getFinanceDetails(product, total, depositPercentage, updateFinanceDetails);
        }
        */
    });
    jQuery('select[name="student_country"]').trigger('change');

    jQuery('#instalments-selector').change(function(event) {
       jQuery('input[name="instalments"]').val(jQuery(this).val());
       let amount = jQuery('#instalments-selector option:selected').text().split(' ').pop();
       let bits = jQuery('#number-of-instalments').text().split(' ');
       bits[0] = jQuery(this).val();
       bits.pop();
       bits.push(amount);
       jQuery('#number-of-instalments').text(bits.join(' '));
    });

    jQuery('html').on('click', '#terms-popup-trigger', function(event) {
        jQuery.magnificPopup.open({
            items: {
                src: '#terms-popup'
            },
            type: 'inline',
            closeMarkup: closeMarkup
        });
    });

    jQuery('input[name="cardnumber"]').keyup(function(event) {
        jQuery(this).val(jQuery(this).val().replace(/ /g, ''));
        if (jQuery(this).val().length < 4) {
            jQuery(this).removeClass('a m v d ma e');
            jQuery('select[name="paymentMethod"]').val('');
            return;
        }
        var card;

        if (card = creditCardType(jQuery(this).val())) {
            switch (card[0].type) {
                case 'american-express':
                    jQuery(this).addClass('a');
                    jQuery('select[name="paymentMethod"]').val('A');
                break;

                case 'master-card':
                    jQuery(this).addClass('m');
                    jQuery('select[name="paymentMethod"]').val('M');
                break;

                case 'visa':
                    jQuery(this).addClass('v');
                    jQuery('select[name="paymentMethod"]').val('V');
                break;

                case 'delta':
                    jQuery(this).addClass('d');
                    jQuery('select[name="paymentMethod"]').val('V');
                break;

                case 'maestro':
                    jQuery(this).addClass('ma');
                    jQuery('select[name="paymentMethod"]').val('MA');
                break;

                case 'visa-electron':
                    jQuery(this).addClass('e');
                    jQuery('select[name="paymentMethod"]').val('V');
                break;
            }
        }
    });

    let closeMarkup = '<button title="Close (Esc)" type="button" class="mfp-close">×</button>';

    var addEvent = function(obj, evt, fn) {
        if (obj.addEventListener) {
            obj.addEventListener(evt, fn, false);
        }
        else if (obj.attachEvent) {
            obj.attachEvent("on" + evt, fn);
        }
    };


    addEvent(document, "mouseout", function(event) {
        event = event ? event : window.event;
        var from = event.relatedTarget || event.toElement;
        if ( (!from || from.nodeName == "HTML") && event.clientY <= 100 ) {
            checkCookie();
        }
    });

    function setCookie(cname,cvalue,exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function checkCookie() {
        var popup=getCookie("popupblackfriday");
        if (popup != "yes") {
            jQuery('#blackfriday-popup').removeAttr('hidden');

            if (jQuery('#blackfriday-popup').length > 0) {
                jQuery.magnificPopup.open({
                    items: {
                        src: '#blackfriday-popup'
                    },
                    type: 'inline',
                    closeMarkup: closeMarkup
                });

            }
            setCookie("popupblackfriday", 'yes', 1);
        }
    }
    async function file_get_contents(uri, callback) {
        let res = await fetch(uri),
            ret = await res.text();
        return callback ? callback(ret) : ret; // a Promise() actually.
    }

    if (jQuery('.finance-per-month-course').length > 0) {
        var payplan = jQuery$("input[name=payplan]").val();
        var method = jQuery("input[name=studymethod]").val();
        var onlineTotal = jQuery('#instalmentprice_online').text();
        var packTotal = jQuery('#instalmentprice_coursepack').text();
        var total = 0;
        if(method == 'online' && onlineTotal>0) {
            total = onlineTotal;
        }
        if(method=='course-pack' && packTotal>0) {
            total = packTotal;
        }
        setFinanceCourseOptions(total, updateFinanceOptions);
    }

    if (jQuery('#numberOfReviews').length > 0) {

        // let trustpilot = file_get_contents("https://api.trustpilot.com/v1/business-units/4d94e64900006400050f5e27?apikey=kJOEHHuRh49Wg2NqJBrjWE7OADtHh3Uw");
        // trustpilot.then(function (result) {
        //     var result = JSON.parse(result);
        //     document.getElementById("numberOfReviews").innerHTML = result.numberOfReviews.total;
        // });
    }

    function recalculateTotal() {
        let total = parseFloat(subTotal);
        

        jQuery('input[name="addon[]"]').each(function(){
            if (this.checked) {
                total += parseFloat(this.getAttribute('cost'));
            } 
        });

        if (parseFloat(jQuery('select[name="student_country"] option:selected').text().split(getCookie('symbol'))[1]) > 0) {
            console.log(parseFloat(jQuery('select[name="student_country"] option:selected').text().split(getCookie('symbol'))[1]));

            total += parseFloat(jQuery('select[name="student_country"] option:selected').text().split(getCookie('symbol'))[1]);
        }
        console.log('total', total);

        jQuery('.current_total').html(getCookie('symbol') + parseFloat(total).toLocaleString('en-GB', {
            minimumFractionDigits: 2,      
            maximumFractionDigits: 2,
        }));
        
        jQuery('#amount').val(total);

        if (jQuery('input[name="finance-term"]').length > 0) {

            let product = jQuery('#finance-term-select').val();

            jQuery('input[name="finance-term"]').val(product);
            let depositPercentage = jQuery('#finance-deposit-select').val();

            finance.getFinanceDetails(product, total, depositPercentage, updateFinanceDetails);
        }
    }

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
});
