async function file_get_contents(uri, callback) {
    let res = await fetch(uri),
        ret = await res.text();
    return callback ? callback(ret) : ret; // a Promise() actually.
}

jQuery(document).ready(function() {
    // let trustpilot = file_get_contents("https://api.trustpilot.com/v1/business-units/4d94e64900006400050f5e27?apikey=kJOEHHuRh49Wg2NqJBrjWE7OADtHh3Uw");
    // trustpilot.then(function(result) {
    //     var result = JSON.parse(result);
    //     //document.getElementById("numberOfReviews").innerHTML = result.numberOfReviews.total;
    //     document.getElementById("numberOfReviews").innerHTML = '3,649';
    // });
    document.getElementById("numberOfReviews").innerHTML = '3,649';
});
