export function getRowHeight() {
     var rowHeight =jQuery('.body.current .wrap').height()+150;
    // jQuery('.wizard .content').css( 'min-height', rowHeight );
}

export function resetRowHeight() {
    var rowHeight = jQuery('.body.current .wrap').height()-120;
    jQuery('.body.current .wrap').css( 'height', 1110 );
    jQuery('.wizard .content').css( 'min-height', 1362 );
    jQuery('.wizard .content').css( 'overflow', 'hidden' );
}

function getCurrentTitle() {
    var currentTitle = jQuery('.title.current').text();
    jQuery('.current-title').text( currentTitle );
}

function setButtonId(){
    jQuery('.actions a[href*="finish"]').prop('id', submitbtn_id);
    jQuery('.actions a[href*="finish"]').prop('class', 'checkout-submit-button');
    $('.actions a[href*="finish"]').prop('class', 'checkout-submit-button');
}

function checkPhoneAndEmail(myUrl) {
    var validateForm=true;

    var phone_error = 1;
    var email_error = 1;
    var pcaKey = 'RU29-JW76-FF25-GH29';
    var Promise = require('promise-polyfill').default;
    let phonecheck_promise = new Promise(function (resolve, reject) {
            var phoneCheck = 0;
            var country = jQuery('#student_country').val();
            if (country === 'GB1' || country === 'GB2' || country === 'GB3') {
                country = 'GB';
            }

            setTimeout(function () {
                console.log('Phone check started');
                var phoneUrl = "https://api.addressy.com/PhoneNumberValidation/Interactive/Validate/v2.20/json3.ws";
                jQuery.post(phoneUrl, {
                    Key: pcaKey,
                    Phone: jQuery("#student_phone").val(),
                    Country: country
                }, function (data) {console.log('Phone check responce received with:'+data);
                    if (data.Items[0].IsValid === 'No') {
                        //location.href = ".student_phone";
                        jQuery("#loqate-phone-error").show();
                        jQuery("#student_phone").addClass('error');
                        phoneCheck = phoneCheck + 1;
                    } else {
                        if (phoneCheck > 0 || phoneCheck == null) {
                            phoneCheck = 0;
                        }
                    }
                    resolve(phoneCheck);
                });

            }, 1000);

    });
    let emailcheck_promise = new Promise(function (resolve, reject) {
        var emailCheck = 0;
        setTimeout(function () {
            console.log('Email check started');
            var emailUrl = "//api.addressy.com/EmailValidation/Interactive/Validate/v2.00/json3.ws";
            jQuery.getJSON(emailUrl, {
                Key: pcaKey,
                Email: jQuery('#student_email').val()
            }, function (data) {console.log('Email check responce received with:'+data);
                if (data.Items[0].ResponseCode === 'Invalid') {
                    jQuery("#loqate-email-error").show();
                    jQuery("#student_email").addClass('error');
                    //location.href = ".student_email";
                    emailCheck = emailCheck + 1;
                } else {
                    if (emailCheck > 0 || emailCheck == null) {
                        emailCheck = 0;
                    }
                }
                resolve(emailCheck);
            });
        }, 1000);

    });

    phonecheck_promise.then(function (phoneCheck) {
        console.log('Phone check inside promise');
        if (phoneCheck > 0) {
            console.log('Error');
            phone_error = 1;
            validateForm=false;
        } else {
            phone_error = 0
            if (email_error == 0) {
                    validateForm=true;
                    validateStep1=true;
                    jQuery("#checkout-form-submit").steps('next');
            }
        }
    });

    emailcheck_promise.then(function (emailCheck) {
        console.log('Email check inside promise');
        if (emailCheck > 0) {
            email_error = 1;
            validateForm=false;
        } else {
            email_error = 0;
            if (phone_error == 0) {
                validateForm=true;
                validateStep1=true;
                jQuery("#checkout-form-submit").steps('next');
            }
        }
    });

        //if(phone_error == 0 && email_error == 0) jQuery('.form-to-validate').submit();


    //
    // jQuery.ajax({
    //     url: myUrl,
    //     type: "GET",
    //     //async false is depreciated to wait for ajax call to return you have to use Promises, callback or await
    //     //async: false,
    //     timeout: 50000,
    //     success: function (result){
    //         //console.log(result);
    //         //console.log(validateForm);
    //         jQuery("#loqate-email-error").hide();
    //         jQuery("#student_email").removeClass('error');
    //         jQuery("#loqate-phone-error").hide();
    //         jQuery("#student_phone").removeClass('error');
    //         if (typeof(result.RequestProcessed) != "undefined")
    //         {
    //             jQuery("#loqate-phone-error").html(result.RequestProcessed +" defined");
    //         }
    //         else
    //         {
    //             if (typeof(result) != "undefined")
    //             {
    //                 let jsonRequestResult=JSON.parse(result);
    //                 if ((typeof(jsonRequestResult.emailCheck.ResponseCode) != undefined) &&
    //                     (typeof(jsonRequestResult.emailCheck.isDisposableOrTemporary) != undefined) &&
    //                     (typeof(jsonRequestResult.emailCheck.isComplainerOrFraudRisk) != undefined) )
    //                 {
    //                     if ("Valid" === jsonRequestResult.emailCheck.ResponseCode && !jsonRequestResult.emailCheck.isDisposableOrTemporary && !jsonRequestResult.emailCheck.isComplainerOrFraudRisk )
    //                     {
    //                         //jQuery("#loqate-email-error").show();
    //                         //jQuery("#loqate-email-error").html(jsonRequestResult.emailCheck.ResponseCode +" everything ok " + result);
    //                     }
    //                     else
    //                     {
    //                         jQuery("#loqate-email-error").show();
    //                         jQuery("#student_email").addClass('error');
    //                         validateForm=false;
    //                         //console.log(validateForm);
    //                     }
    //                 }
    //                 if (typeof(jsonRequestResult.phoneCheck.ResponseCode) != undefined)
    //                 {
    //                     if ("Yes" === jsonRequestResult.phoneCheck.IsValid )
    //                     {
    //                     }
    //                     else
    //                     {
    //                         jQuery("#loqate-phone-error").show();
    //                         jQuery("#student_phone").addClass('error');
    //                         validateForm=false;
    //                         //jQuery("#loqate-phone-error").html(result +" undefined");
    //                         //console.log(validateForm);
    //                     }
    //                 }
    //             }
    //         }
    //         //getRowHeight();
    //         //console.log(validateForm);
    //         jQuery("#phone-email-validation").hide();
    //         if (validateForm)
    //         {
    //             validateStep1=validateForm;
    //             //this retriggers die onStepChanging method when ajax call is finished
    //             jQuery("#checkout-form-submit").steps('next');
    //         }
    //         //would only work with async: false which is depreciated
    //         // return validateForm;
    //     },
    //     error: function(error){
    //         //console.log('Error jQuery{error)')
    //         //getRowHeight();
    //         jQuery("#phone-email-validation").hide();
    //         //give up on external validation it there is a timeout
    //         validateStep1=true;
    //         jQuery("#checkout-form-submit").steps('next');
    //     }
    // });
    //return validateForm;
}


export function setFinanceApplicantDetails(){
    jQuery('#finance-applicant_title').val(jQuery('#student_title').val());
    jQuery('#finance-applicant_firstname').val(jQuery('#student_firstname').val());
    jQuery('#finance-applicant_lastname').val(jQuery('#student_lastname').val());
    jQuery('#finance-applicant_dob').val(jQuery('#student_dob').val());
    jQuery('#finance-applicant_email').val(jQuery('#student_email').val());
    jQuery('#finance-applicant_phone').val(jQuery('#student_phone').val());
    jQuery('#finance-applicant_address1').val(jQuery('#student_address1').val());
    jQuery('#finance-applicant_address2').val(jQuery('#student_address2').val());
    jQuery('#finance-applicant_city').val(jQuery('#student_city').val());
    jQuery('#finance-applicant_country').val(jQuery('#student_country').val());
    jQuery('#finance-applicant_postcode').val(jQuery('#student_postcode').val());
}

export function clearFinanceApplicantDetails() {
    jQuery('#finance-applicant_title').val("");
    jQuery('#finance-applicant_firstname').val("");
    jQuery('#finance-applicant_lastname').val("");
    jQuery('#finance-applicant_dob').val("");
    jQuery('#finance-applicant_email').val("");
    jQuery('#finance-applicant_phone').val("");
    jQuery('#finance-applicant_address1').val("");
    jQuery('#finance-applicant_address2').val("");
    jQuery('#finance-applicant_city').val("");
    jQuery('#finance-applicant_country').val("");
    jQuery('#finance-applicant_postcode').val("");
}
var form = jQuery("#checkout-form-submit").show();

var validateStep1=false;
var validateStep1DoneOnce=false;
var returnedFromLocateValidation=false;

if (jQuery("#checkout-form-submit").length > 0)
{
    // $.validator.addMethod(
    //     "australianDate",
    //     function(value, element) {
    //         // put your own date format
    //         return value.match(/^\d\d?\/\d\d?\/\d\d\d\d$/);
    //     },
    //     "Please enter a date in the format dd/mm/yyyy."
    // );

    form.validate({
        rules: {
            card_number: {
                creditcard: true
            },
            addressfinder_student:{
                required: function (element) {
                    return jQuery("#student_address1").val().length > 0;
                }
            },
            //student_address1:"required",
            //debug: true
            // student_dob:{
            //     australianDate : true
            // }
        },
    });

    form.steps({
        headerTag: 'h3',
        bodyTag: 'section',
        // transitionEffect: 'slideLeft',
        autoFocus: true,
        actionContainerTag: 'test',
        titleTemplate: '<span class="number">#index#</span> #title#',
        labels: {
            finish: submitbtn_text,
            next: 'Continue',
            previous: 'Back'
        },
        onInit: function (event, currentIndex, newIndex)
        {
            getRowHeight();
            getCurrentTitle();
            setButtonId();
            jQuery('.progress-nav').append(jQuery('.steps').html());
        },
        onStepChanging: function (event, currentIndex, newIndex)
        {
            if (currentIndex == 0)
            {
                //form.validate().settings.ignore = "";
                //check if ajax call has changes validateStep1 to true
                //console.log("validateStep1DoneOnce:" + validateStep1DoneOnce);
                //console.log("returnedFromLocateValidation:" + returnedFromLocateValidation);
                if(jQuery("#student_address1").val().length <= 0){
                    console.log('validateStep1; '+validateStep1);
                    jQuery("#addressfinder_student").after('<label id="addressfinder_student-error" class="error" for="addressfinder_student">Please search for your address and select from the list</label>')
                    return false;
                }

                if (validateStep1)
                {
                    validateStep1 = false;
                    return true;
                }
            }
            // Always allow going backward even if the current step contains invalid fields!
            if (currentIndex > newIndex)
            {
                return true;
            }

            //var form = jQuery("#checkout-form-submit");

            // Clean up if user went backward before
            if (currentIndex < newIndex)
            {
                // To remove error styles
                jQuery(".body:eq(" + newIndex + ") label.error", form).remove();
                jQuery(".body:eq(" + newIndex + ") .error", form).removeClass("error");
            }
            // Disable validation on fields that are disabled or hidden.
            //form.validate().settings.ignore = ":disabled,:hidden";
            var validateForm = form.valid();
            //do loqate only once
            if (validateStep1DoneOnce)
            {
                returnedFromLocateValidation = true;
            }
            if (!validateStep1DoneOnce && validateForm && (currentIndex == 0))
            {
                validateStep1DoneOnce = true;
                //alert("Try validation in onStepChanging" + currentIndex);
                let myUrl = 'api/phoneEmailCheck';
                myUrl = myUrl + '?Phone=' + jQuery('#student_phone').val();
                myUrl = myUrl + '&Email=' + jQuery('#student_email').val();
                myUrl = myUrl + '&Timeout=15000&Token=36d363aa3fec0c95e124cd3089b13c3afc0fcc52b366264f9080e4799cded8bbe63e79d36e039a6f78edebcca64c46f6e986f7c295aa5211d822710f251e7f6789e8ab6d16fd3822d58367d4566b0922c7ef00a943db1498223aa3860409c33a7ea814bb961d05d93557fbbc27b21fa819ad4cf5bb2f05f2a7618170c26454a7';
                //let myUrl='api/testApi';
                //console.log(myUrl);
                //async=false for ajax call is depreciated, need to always return false in onStepChanging and the set next step in ajax call
                //validateForm=checkPhoneAndEmailNotAsync(myUrl);
                jQuery("#phone-email-validation").show();
                checkPhoneAndEmail(myUrl);
            } else
            {
                //getRowHeight();
            }
            //Check if ajax has finished (how?)
            // Start validation; Prevent going forward if false
            if (currentIndex == 0)
            {
                //do loqate only once
                if (returnedFromLocateValidation && validateForm)
                {
                    validateStep1DoneOnce = false;
                    returnedFromLocateValidation = false;
                    return true;
                }
                //for step one progress to step 2 only if ajax returns true
                return false;
            }
            return validateForm;
        },
        onStepChanged: function (event, currentIndex, newIndex)
        {
            jQuery('.progress-nav').html('');
            jQuery('.progress-nav').append(jQuery('.steps').html());

            var aTag = jQuery("a[name='formstart']");
            jQuery('html,body').animate({
                scrollTop: aTag.offset().top
            }, 'slow');
            if(currentIndex == 1){
                jQuery.ajax({
                        url: 'api/create-student-detail-lead',
                    type: "GET",
                    timeout: 5000,
                    data: jQuery(form).serialize(),
                    success: function (result){console.log(result);}
            });
            }
            if (currentIndex == 1 && jQuery('#finance-term').val()) {
                window.dataLayer.push({
                    'event': 'finance_student_details'
                });
                jQuery('input:radio[value=student]').prop('checked', true);
                jQuery("#finance_details_container").hide();
                setFinanceApplicantDetails();
            }

            if (currentIndex == 1 && payplan == 'pay_now') {
                window.dataLayer.push({
                    'event': is_finance_decline ? 'df_pay_now_student_details' : 'pay_now_student_details'
                });
            }

            if (currentIndex == 1 && payplan == 'installment') {
                if (is_deposit_paid) {
                    window.dataLayer.push({
                        'event': 'dd_bank_details'
                    });
                } else {
                    window.dataLayer.push({
                        'event': 'dd_student_details'
                    });
                }
            }

            if (currentIndex == 2 && jQuery('#finance-term').val()) {
                window.dataLayer.push({
                    'event': 'finance_preview'
                });
            }

            if (currentIndex == 2 && payplan == 'pay_now') {
                window.dataLayer.push({
                    'event': is_finance_decline ? 'df_pay_now_payment_details' : 'pay_now_payment_details'
                });
            }

            if (currentIndex == 2 && payplan == 'installment') {
                window.dataLayer.push({
                    'event': 'dd_deposit_details'
                });
            }

            getRowHeight();
            getCurrentTitle();
            return true;
        },
        onFinishing: function (event, currentIndex)
        {
            //var form = jQuery("#checkout-form-submit");

            // Disable validation on fields that are disabled.
            // At this point it's recommended to do an overall check (mean ignoring only disabled fields)
            form.validate().settings.ignore = ":disabled";

            var validateForm = form.valid();
            getRowHeight();
            if (validateForm)
            {
                jQuery('.error').remove();
            }

            // Start validation; Prevent form submission if false
            return validateForm;
        },
        onFinished: function (event, currentIndex)
        {
            getRowHeight();
            form.find('a').off('click');

            if (!(jQuery.inArray(submitbtn_id, ['order_attempt_button', 'direct_debit_order_attempt_button']) >= 0))
            {
                form.submit();
            }
        }
    });
}

jQuery(window).on('resize', function(){
    getRowHeight();
});
