var $ = jQuery.noConflict();
let submitCheckoutForm = function(buttonid) {
    if(payplan == 'finance'){
        window.dataLayer.push({
            'event': 'finance_proceed'
        });
    }
    if(payplan == 'pay_now'){
        window.dataLayer.push({
            'event': (is_finance_decline?'df_pay_now_proceed':'pay_now_proceed')
        });
    }
    if(payplan == 'installment'){
        if(is_deposit_paid){
            window.dataLayer.push({
                'event': 'dd_bank_details'
            });
        }
        else {
            window.dataLayer.push({
                'event': 'dd_deposit_proceed'
            });
        }
    }
    //console.log(buttonid);
    if($.inArray(buttonid, ['order_attempt_button', 'direct_debit_order_attempt_button'])>=0 && ($('input[name="odrder_status"]').length == 0)) {

        // buttonid == 'order_attempt_button'
        $.ajax({
            type: "POST",
            url: oscdomain + "/first-data-hex",
            data: {chargetotal: $("#amount").val(), currency: $("#currency").val(), txndatetime: $("#datetime").val()},
            success: function (result) {
                console.log('success - '+result);
                $("#hash").val(result);

                $.ajax({
                    type: "POST",
                    url: oscdomain + "/first-data-form-handler",
                    data: $("form :input").serialize(),
                    success: function (result) {
                        console.log('form-handler -'+result);
                        $("#oid").val(result);
                        $('#checkout-form-submit').submit();
                    }
                });
            },
            complete: function(event){
                console.log('complete - '+event);
            }
        });
    }

    if (payplan == 'finance') {
        const formData = jQuery('#checkout-form-submit').serialize();

        jQuery.ajax({
            url: jQuery('#checkout-form-submit').attr('action'),
            type: 'POST',
            data: formData,
            success: function (data) {
                const form = jQuery('body').append('<form id="payl8rForm" method="POST" action="' + data.url + '"></form>').find('#payl8rForm');
                // const decodedData = JSON.parse(data);

                jQuery(form).append('<input type="hidden" name="rid" value="' + data.username + '">');
                jQuery(form).append('<input type="hidden" name="data" value="' + data.basics + '">');
                jQuery.each(data.items, function (key, value) {
                    jQuery(form).append('<input type="hidden" name="item_data[]" value="' + value + '">');
                });
                jQuery(form).submit();
                setTimeout(function(){
                    jQuery('#financeRedirectOverlay').removeClass('d-none');
                }, 3000);
            }
        })
    }
};

jQuery(document).on('click', '#payl8rManualBtn', function(){
    jQuery('#payl8rForm').submit();
});

// Validates that the input string is a valid date formatted as "dd/mm/yyyy"
function isValidDate(dateString)
{
    // First check for the pattern
    if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
        return false;

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month == 0 || month > 12)
        return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
};

$(document).ready(function() {

    if ( $('#student_dob').length && $('#student_dob')[0].type != 'date' ) {
        var $J = $.noConflict();
        $J('#student_dob').datepicker({
            dateFormat: "yy-mm-dd",
            yearRange: "-100:+0",
            changeYear:true,
            changeMonth:true,
        });
    }

    // if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    //     //Bind keyup/keydown to the input
    //     jQuery('#student_dob').bind('keyup', 'keydown', function (e) {
    //
    //         //To accomdate for backspacing, we detect which key was pressed - if backspace, do nothing:
    //         if (e.which !== 8) {
    //             var numChars = jQuery('#student_dob').val().length;
    //             if (numChars === 2 || numChars === 5) {
    //                 var thisVal = jQuery('#student_dob').val();
    //                 thisVal += '/';
    //                 jQuery('#student_dob').val(thisVal);
    //             }
    //         }
    //         if (jQuery('#student_dob').val().length == 10) {
    //             if (isValidDate(jQuery('#student_dob').val())) {
    //                 $("#student_dob_format-error").hide();
    //                 $("#student_dob_format-error").removeClass('error');
    //                 $("#student_dob").removeClass('error');
    //             } else {
    //                 $("#student_dob_format-error").show();
    //                 $("#student_dob_format-error").html("<br> Date format DD/MM/YYYY is required.");
    //                 $("#student_dob").addClass('error');
    //             }
    //
    //          }
    //     });
    // }

    jQuery(document).on('click', '.checkout-submit-button', function(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        //$(event.target).text('Please Wait...');
        if ($(".error")[0]){
            // checking if there are some errors don't submit form
            event.preventDefault();
        }else if(event.target.id == 'direct_debit_order_attempt_button'){
            $('#'+event.target.id).text('Please wait');
            $('#'+event.target.id).addClass('button-loading');
            $('#'+event.target.id).prop("disabled", true);
            submitCheckoutForm(event.target.id);
            //$(event.target.closest('form')).submit();
        }else if(event.target.closest('form').id == 'checkout-form-submit') {
            $('#'+event.target.id).text('Please wait');
            $('#'+event.target.id).addClass('button-loading');
            $('#'+event.target.id).prop("disabled", true);
            submitCheckoutForm(event.target.id);
        }else{
            event.preventDefault();

        }
    });
});
