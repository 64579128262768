//import {FinanceDetails} from "./deko";

export function FinanceDetails(product_type, cost_of_goods, deposit_percentage, deposit_amount) {
    deposit_amount = typeof deposit_amount !== 'undefined' ?  deposit_amount : 0;

    // workout product details
    switch (product_type) {
        case "IBC-12-169":
            this.apr = 16.9;
            this.rate_of_interest = '16.9% (Fixed)';
            this.term = 12;
            this.p_name = '12 Months Promotional Credit (16.9%)';
            this.subsidy_lower = '0';
            this.subsidy_upper = '0';
            this.p4l_min_fee = 0;
            this.lender_min_fee = 0;
            this.l_min = 250;
            this.l_max = 15000;
            this.factor = 0.050040141717906910369922976593;
            this.fees = 1;
            break;
        case "IBC-24-169":
            this.apr = 16.9;
            this.rate_of_interest = '16.9% (Fixed)';
            this.term = 24;
            this.p_name = '24 Months Promotional Credit (16.9%)';
            this.subsidy_lower = '0';
            this.subsidy_upper = '0';
            this.p4l_min_fee = 0;
            this.lender_min_fee = 0;
            this.l_min = 250;
            this.l_max = 15000;
            this.factor = 0.050040141717906910369922976593;
            this.fees = 1;
            break;
        case "IBC-36-169":
            this.apr = 16.9;
            this.rate_of_interest = '16.9% (Fixed)';
            this.term = 36;
            this.p_name = '36 Months Promotional Credit (16.9%)';
            this.subsidy_lower = '0';
            this.subsidy_upper = '0';
            this.p4l_min_fee = 0;
            this.lender_min_fee = 0;
            this.l_min = 250;
            this.l_max = 15000;
            this.factor = 0.050040141717906910369922976593;
            this.fees = 1;
            break;
        case "IBC-12-149":
            this.apr = 14.9;
            this.rate_of_interest = '14.9% (Fixed)';
            this.term = 12;
            this.p_name = '12 Months Promotional Credit (14.9%)';
            this.subsidy_lower = '0';
            this.subsidy_upper = '0';
            this.p4l_min_fee = 0;
            this.lender_min_fee = 0;
            this.l_min = 250;
            this.l_max = 15000;
            this.factor = 0.050040141717906910369922976593;
            this.fees = 1;
            break;
        default:
            return null;
            break;
    }
    // cost of goods
    this.goods_val = cost_of_goods * 100;
    // in the event that a deposit amount is being set instead of a deposit percentage
    if (deposit_percentage > 0) {
        // deposit percentage
        this.d_pc = deposit_percentage;
        // deposit amount
        this.d_amount = Math.round((this.goods_val / 100) * this.d_pc);
    } else {
        // deposit amount
        this.d_amount = deposit_amount * 100;
        // deposit percentage
        this.d_pc = (this.d_amount / this.goods_val) * 100;
    }
    // amount of credit
    this.l_amount = (this.goods_val - this.d_amount) * this.fees;

    this.m_full_inst = this.l_amount * this.factor;
    if(product_type.toString().substring(0, 2) === 'BL' || product_type.toString().substring(0, 2) === 'PB') {
        this.firstRepayment = 0;
        this.last_repayment = this.credit;
    } else if (this.apr > 0) {
        this.m_inst = Math.round(this.m_full_inst);
    } else {
        this.m_inst = Math.floor(this.m_full_inst  + 0.0001);
    }
    var diff = (this.m_full_inst - this.m_inst) * this.term;
    this.first_repayment = Math.round(this.m_inst + diff);
    this.last_repayment = this.m_inst;	if(product_type.toString().substring(0, 2) === 'BL' || product_type.toString().substring(0, 2) === 'PB') {
        this.first_repayment = 0;
        this.last_repayment = this.credit;
    }

    this.l_repay = this.first_repayment + (this.m_inst * (this.term - 2)) + this.last_repayment;

    // total cost
    this.total = this.l_repay + this.d_amount;
    // display 'incorrect' total (goods amount) for interest free
    if (this.apr == 0) {
        this.total = this.goods_val;
    }
    // cost of loan
    this.l_cost = this.l_repay - this.l_amount;
    // retailer subsidy
    if ((this.l_amount / 100) <= 1250) {
        this.r_subsidy = (this.l_amount / 100) * ((parseFloat(this.subsidy_upper)) / 100);
    } else {
        this.r_subsidy = (this.l_amount / 100) * ((parseFloat(this.subsidy_lower)) / 100);
    }
    if (this.r_subsidy < 0) {
        this.r_subsidy = 0;
    }
    if (this.r_subsidy < (this.lender_min_fee + this.p4l_min_fee)) {
        this.r_subsidy = (this.lender_min_fee + this.p4l_min_fee);
    }
    // formatting values
    this.goods_val = (this.goods_val / 100).toFixed(2);
    //this.d_pc = this.d_pc.toFixed(2);
    this.d_amount = (Math.ceil(this.d_amount) / 100).toFixed(2);
    this.l_amount = (this.l_amount / 100).toFixed(2);
    this.m_inst = Math.round(this.m_inst) / 100;
    this.l_repay = (this.l_repay / 100).toFixed(2);
    this.total = (this.total / 100).toFixed(2);
    this.l_truecost = (this.l_cost / 100).toFixed(2);
    this.l_cost = (this.l_cost < 0) ? (0) : (this.l_cost / 100);
    this.l_cost = this.l_cost.toFixed(2);
    this.r_subsidy = this.r_subsidy.toFixed(2);

    this.valid=true;
    if(this.l_amount>this.l_max || this.l_amount<this.l_min){
        this.valid=false;
    }
}
export function refreshFinancePricing() {
    jQuery('.course__pricing-afforditnow').each(function () {
        if (jQuery(this).find('.finance-price').length > 0) {
            var financePrice = parseFloat(jQuery(this).find('.finance-price').first().text().split("£")[1]);
        } else {
            var payNowPrice = jQuery(this).find('.pay-now-price').first().text().split("£")[1];
            var financePrice = parseFloat(parseFloat(payNowPrice) + parseFloat(financeFee));
        }

        if (financePrice > 0) {
            var financeDetails = new FinanceDetails(defaultFinanceProduct, parseFloat(financePrice), defaultFinanceDeposit, 0);
            var perMonth = parseFloat(Math.round(100 * financeDetails.m_inst) / 100).toFixed(2);
            jQuery(this).find('.finance-per-month').first().text('£' + perMonth);
            jQuery(this).find('.finance-per-month-stick').first().text('From: £' + perMonth);
        }
    });
    console.log('Refreshing finance pricing');
}

export function getFinanceDetails(product, amount, deposit, callback, options = {}) {
    let data = {
        amount,
        product,
        deposit
    };

    jQuery.ajax({
        type: 'GET',
        url: '/finance-details',
        data: data,
        complete: function (data) {
            console.log(data.responseJSON);
            callback(data.responseJSON, options);
        }
    });
}
