
import * as Paybreak from './paybreak';

switch (window.financeCompany) {
    case 'Paybreak':
    case 'AffordItNow':
        var finance = Paybreak;
        break;
}

export { finance };
